<script setup lang="ts">
const props = defineProps({
    attrs: {
        default: () => ({}),
        type: Object as PropType<string | any>,
    },
    disabled: {
        default: false,
        type: Boolean,
    },
    error: {
        default: null,
        type: String,
    },
    fullWidth: {
        default: false,
        type: Boolean,
    },
    highlighted: {
        default: false,
        type: Boolean,
    },
    info: {
        default: null,
        type: String,
    },
    infoLabel: {
        default: 'Hinweis',
        type: String,
    },
    label: {
        default: null,
        type: String,
    },
    limit: {
        default: null,
        type: Number,
    },
    modelValue: {
        default: '',
        type: String as PropType<string | `${number}`>,
    },
    name: {
        default: 'textarea',
        type: String,
    },
    placeholder: {
        default: '',
        type: String,
    },
    readonly: {
        default: false,
        type: Boolean,
    },
    resize: {
        default: false,
        type: Boolean,
    },
    rows: {
        default: '3',
        type: String,
    },
});

const emit = defineEmits(['change', 'update:modelValue']);

const id = useId();
const textAreaElement = ref(null);
const value = ref(props.modelValue);

watch(value, () => {
    emit('change', value.value);
    emit('update:modelValue', value.value);
});

watch(
    () => props.modelValue,
    (newValue) => {
        value.value = newValue;
    },
);
</script>

<template>
    <label :for="id" class="block text-left" :class="[fullWidth && 'w-full']">
        <div
            v-if="label || info"
            class="mb-2 flex items-end justify-between gap-4"
        >
            <JamBaseText
                v-if="label"
                variant="small"
                :title="label"
                :is-label="true"
            >
                {{ label }}
            </JamBaseText>
            <div class="shrink-0">
                <JamBaseTooltip v-if="info || $slots.info">
                    <template #info><slot name="info" />{{ info }}</template>
                    <JamBaseText
                        class="cursor-help border-b border-dashed border-gray-600 text-gray-600 hover:text-gray-900"
                        variant="small"
                    >
                        {{ infoLabel }}
                    </JamBaseText>
                </JamBaseTooltip>
            </div>
        </div>

        <div
            class="flex items-center gap-1 rounded border border-gray-300 bg-white has-[textarea:disabled]:cursor-not-allowed has-[textarea:active]:border-gray-900 has-[textarea:focus]:border-gray-900 has-[textarea:invalid]:border-red-700 has-[textarea:disabled]:opacity-40 has-[textarea:focus-visible]:outline has-[textarea:focus-visible]:outline-2 has-[textarea:focus-visible]:outline-offset-2 has-[textarea:focus-visible]:outline-blue-600"
            :class="[
                error && error !== '' && 'border-red-700',
                highlighted && 'border-yellow-600',
            ]"
        >
            <textarea
                :id="id"
                ref="textAreaElement"
                v-model="value"
                v-bind="attrs"
                :name="name"
                :rows="rows"
                :disabled="disabled"
                :placeholder="placeholder"
                :readonly="readonly"
                :maxlength="limit"
                class="peer w-full rounded bg-white p-4 text-gray-900 focus:outline-none focus-visible:outline-none"
                :class="[!resize && 'resize-none']"
            />
        </div>
        <div class="mt-2 flex items-center justify-between gap-4">
            <JamBaseText v-if="error" variant="small" class="text-red-700">
                {{ error }}
            </JamBaseText>
            <JamBaseCharacterCountIndicator
                v-if="limit"
                class="ms-auto"
                :count="value.length"
                :limit="limit"
            />
        </div>
    </label>
</template>
