<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
    count: {
        default: 0,
        type: Number,
    },
    limit: {
        default: 500,
        type: Number,
    },
});

const characterCount = computed(() => {
    return props.limit - props.count;
});

const percentage = computed(() => {
    return ((props.limit - characterCount.value) / props.limit) * 100;
});
</script>

<template>
    <div class="flex items-center justify-end gap-2">
        <JamBaseText
            variant="small"
            :class="[characterCount < 0 ? 'text-red-700' : 'text-gray-500']"
        >
            {{ characterCount }}
        </JamBaseText>

        <div class="flex items-center" style="--el-fill-color-light: #c9cdd4">
            <el-progress
                type="circle"
                :color="[
                    { color: '#8bc72a', percentage: 75 },
                    { color: '#f7c01b', percentage: 100 },
                    { color: '#cd463a', percentage: 100 },
                ]"
                :percentage="percentage"
                :stroke-width="2"
                :text-inside="true"
                :width="14"
            />
        </div>
    </div>
</template>
